$theme-colors: (
  "primary": #cc8433,
  "secondary": #3c6c3a,
  "back": #ffffff,
  "active": #3c6c3a,
  "myprimary":#6610f2
);

@import "~bootstrap/scss/bootstrap.scss";

$primary: #cc8433;
$secondary: #3c6c3a;
$back: #ffffff;
$active: #3c6c3a;
$primary:#6610f2;

